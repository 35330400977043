import React, { Component } from "react";
import Nav from "../components/Nav";
import JoinTeam from "../components/JoinTeam";
import Contact from "../components/Contact";
import People from "../components/People";
import { Footer } from "../components/Footer";


class Team extends Component {
    constructor(props) {
        super(props)
        this.state = {
            referrer: null
        }
    }


    render() {
        return(
            <div>
            <Nav />
            <People />
            <JoinTeam />            
            <Contact />
            <Footer />
            </div>
        )
    }
}

export default Team;